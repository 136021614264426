<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="approvalz">
        <heade></heade>
        <div class="con">
            <div class="box">
                <ul class="left">
                    <li v-for="(i,index) in list" :key="index" @click="dian_list(i)" :class="i.path==path?'bei':''">{{i.name}}</li>
                </ul>
                <div class="right">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <foote></foote>
    </div>
</template>

<script>
import heade from '../components/Heade'
import foote from '../components/Foote'
export default {
  name: 'approvalz',
  data () {
    return {
      // list:['审批管理'
      // // ,'导出数据'
      // ],
      // inde:0
      list: [
        {
          name: '审批管理',
          path: '/appsett'
        }, {
          name: '表单-代写',
          path: '/modelallsale'
        }, {
          name: '数据导出',
          path: '/appdaochu'
        }
      ],
      path: ''
    }
  },
  components: { heade, foote },
  created () {
    this.lujing(this.$route.path)
    // this.jichu()
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    lujing (path) {
      this.path = path
      // 
    },
    dian_list (i) {
      this.$router.push(i.path)
      //   this.inde=index
      //     if(this.inde==0){
      //         // this.$router.push('/')
      //     }else if(this.inde==1){
      //         // this.$router.push('/')
      //     // }else if(this.inde==2){
      //     //     this.$router.push('/')
      //     // }else if(this.inde==3){
      //     //     this.$router.push('/')
      //     }
      //     sessionStorage.setItem("pc_pc_pc_shenpi", JSON.stringify(this.inde));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.approvalz {
    background:#E7E8EB;
    min-height: 100vh;
    .con{
        width: 100%;
        background:#E7E8EB;
        display: flex;
        padding-top:1.8rem;
        padding-bottom: 0.46rem; 
        .box{
            width:12rem;
            min-height:6rem;
            margin:0 auto;
            background: #fff;
            display: flex;
            .left{
                width: 2.2rem;
                height:100%;
                float: left;
                background-color: #F4F6F8;
                li{
                    height:0.6rem;
                    width: 100%;
                    font-size: 0.18rem;
                    color: #888888;
                    text-align: center;
                    line-height: 0.6rem;
                    cursor:pointer;
                    font-size: 0.16rem;
                }
                .bei{
                    background: #979AA1;
                    color: #fff;
                }
            }
            .right{
                float: left;
                width:9.8rem;
            }
        }
    }
    @media screen and (max-width: 12rem) {
      .con {
        width: 12rem;
      }
    }
}
</style>
